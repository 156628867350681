import React from "react";
import companyLogo from "../../assets/company-logo.svg"
import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <footer className="bg-black flex items-center sm:pb-5 sm:pt-3 py-8 justify-around flex-col md:flex-row gap-5">
                <section className="flex flex-col md:flex-row items-center justify-center sm:gap-0 gap-8 sm:w-fit w-full ">
                    <Link to="/">
                        <img src={companyLogo} alt="Company Logo" className="sm:mr-5 mr-0 sm:ml-0 ml-3 w-32" />
                    </Link>
                    <ul className="flex sm:gap-6 gap-3 h-8 items-end">
                        <Link to="/rent">
                            <li className="f-li text-white sm:text-md text-lg font-thin cursor-pointer">Rent</li>
                        </Link>
                        <Link to="/business">
                            <li className="f-li text-white sm:text-md text-lg font-thin cursor-pointer">Business</li>
                        </Link>
                        <Link to="/about_us">
                            <li className="f-li text-white sm:text-md text-lg font-thin cursor-pointer flex items-center gap-3">About</li>
                        </Link>
                    </ul>
                </section>
                <section className="">
                    <ul className="flex gap-10 items-center mt-2 h-10">
                        <Link to={'https://www.instagram.com/asancars.pk'} target={"_blank"}>
                            <li className="text-white text-md font-thin cursor-pointer rounded-full bg-gray-800 p-1 ">
                                <FaInstagram className="text-lg w-8 h-8"/></li>
                        </Link>
                        <Link to={'https://www.facebook.com/profile.php?id=61558179575774&mibextid=ZbWKwL'} target={"_blank"}>
                            <li className="text-white text-md font-thin cursor-pointer rounded-full bg-gray-800 p-1">
                                <FaFacebook className="text-lg w-8 h-8"/></li>
                        </Link>
                        <Link to={'https://www.linkedin.com/company/asancars/'} target={"_blank"}>
                            <li className="text-white text-md font-thin cursor-pointer rounded-full bg-gray-800 p-1">
                                <FaLinkedinIn className="text-lg w-8 h-8"/></li>
                        </Link>
                    </ul>
                </section>
            </footer>
        </>
    )
}

export default Footer;