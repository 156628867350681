import React from "react";
import { BsFuelPumpFill } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { GiGearStickPattern } from "react-icons/gi";
import { Link } from "react-router-dom";
import {RiSteeringLine} from "react-icons/ri";

const Card = ({ id, img, modelName, price, withDriver, fuelType, transmission, fuelTypeIcon, personCapacity, className, customStyle, imgAlt }) => {
    return (
        <div className={`card lg:w-c30 md:w-80 w-5/6 shadow-md sm:h-7/12 xs:h-96 py-5 px-6 flex flex-col sm:gap-4 gap-3 rounded-xl bg-white ${className}`} style={customStyle}>
            <div className="card-img flex items-center justify-center">
                <img src={img} alt={imgAlt} className="sm:h-96 rounded-xl object-contain w-full" />
            </div>
            <div className="model-name">
                <p className="font-fig sm:text-2xl text-lg truncate font-bold">{modelName}</p>
            </div>
            <div className="rent-price flex">
                <p className="font-fig sm:text-3xl text-lg truncate font-black">{price}</p>
                <p className="font-fig text-md font-bold text-customgrey self-end">/day</p>
            </div>
            <div className="vehicle-info shadow rounded-lg bg-customwhite flex p-2 sm:w-full sm:flex-nowrap flex-wrap justify-around">
                <div className="kilometer flex flex-col items-center gap-1">
                    <RiSteeringLine />
                    <p className="font-fig text-xs">{withDriver}</p>
                </div>
                <div className="transmission-type flex flex-col items-center gap-1">
                    <GiGearStickPattern />
                    <p className="font-fig text-xs">{transmission}</p>
                </div>
                <div className="person-capacity flex flex-col items-center gap-1">
                    <FiUsers />
                    <p className="font-fig text-xs">{personCapacity}</p>
                </div>
                <div className="fuel-type flex flex-col items-center gap-1">
                    {fuelTypeIcon || <BsFuelPumpFill />}
                    <p className="font-fig text-xs">{fuelType}</p>
                </div>
            </div>
            <div className="btn">
                <Link to={`/car_details/${id}`}>
                    <button className="text-center font-fig font-bold transition-all duration-175 border-black border-2 hover:bg-black hover:text-white w-full sm:py-3 py-1.5 rounded-full">Rent Now</button>
                </Link>
            </div>
        </div>
    );
}

export default Card;
