import { CiLocationOn } from "react-icons/ci";
import { FaArrowRight } from "react-icons/fa";
const DropOff = ({customClassName}) => {
    return (
        <section className={`relative drop-off lg:flex-nowrap flex-wrap xl:w-10/12 md:w-5/6 bg-gradient-to-b from-gray-300 via-gray-100 to-gray-50 shadow-lg rounded-lg flex md:px-5 px-8 md:py-8 py-4 items-center justify-center 2xl:justify-between sm:gap-x-7 gap-4 z-50  ${customClassName}`}>
            <div className="pickup-location flex flex-col gap-2 w-full md:w-auto">
                <label className="font-fig text-sm">Pick-up Location</label>
                <div className="border flex justify-around rounded-md py-3 px-1.5 gap-1">
                    <CiLocationOn className="text-md"/>
                    <select name="" className="font-fig text-sm outline-none w-full bg-transparent">
                        <option className="font-fig" value="Hyderabad">Hyderabad, SD, PK</option>
                        <option className="font-fig" value="Karachi">Karachi, SD, PK</option>
                    </select>
                </div>
            </div>
            <div className="pickup-date flex flex-col gap-2 w-full md:w-auto">
                <label className="font-fig text-sm">Pick-up Date</label>
                <div className="border flex justify-center rounded-md py-2 px-1.5 gap-1">
                    <input type="date" className="outline-none w-full bg-transparent" name="" id=""/>
                </div>
            </div>
            <div className="dropoff-location flex flex-col gap-2 w-full md:w-auto">
                <label className="font-fig text-sm">Drop-off Location</label>
                <div className="border flex justify-around rounded-md py-3 px-1.5 gap-1">
                    <CiLocationOn className="text-md"/>
                    <select name="" className="font-fig text-sm outline-none w-full px-2 bg-transparent">
                        <option className="font-fig" value="Hyderabad">Karachi, SD, PK</option>
                        <option className="font-fig" value="Karachi">Hyderabad, SD, PK</option>
                        <option className="font-fig" value="Sukkur">Hyderabad (Same City)</option>
                        <option className="font-fig" value="Sukkur">Karachi (Same City)</option>
                        <option className="font-fig" value="Sukkur">Mirpurkhas, SD, PK</option>
                        <option className="font-fig" value="Sukkur">Tando Allahyar, SD, PK</option>
                        <option className="font-fig" value="Sukkur">Other</option>
                    </select>
                </div>
            </div>
            <div className="dropoff-date flex flex-col gap-2 w-full md:w-auto">
                <label className="font-fig text-sm">Drop-off Date</label>
                <div className="border flex justify-center rounded-md py-2 px-1.5 gap-1">
                    <input type="date" className="outline-none w-full bg-transparent" name="" id=""/>
                </div>
            </div>
            <div className="btn pt-6 w-full md:w-auto items-center justify-center flex">
                <button
                    className="flex items-center text-white bg-black rounded-full py-4 tracking-wide px-3 gap-2 font-fig font-bold h-fit">Find
                    a Vehicle <FaArrowRight/></button>
            </div>
        </section>

    );
};
export default DropOff;