import React from "react";
import Layout from "../../common/components/Layout/Layout"
import car from "../../common/assets/car.webp"
import Toyota from "../../common/assets/Toyota.svg"
import Suzuki from "../../common/assets/suzuki.svg"
import Honda from "../../common/assets/Honda.svg"
import Changan from "../../common/assets/Changan.svg"
import KIA from "../../common/assets/KIA.svg"
import DropOff from "../../common/components/DropOff/Dropoff.jsx";
import Card from "../../common/components/Card/Card.jsx";
import { FaArrowRight } from "react-icons/fa";
import Revo from "../../common/assets/Revo.svg";
import { IoSearchCircleOutline } from "react-icons/io5";
import { TbCalendarCheck, TbMoodHappy } from "react-icons/tb";
import {Link} from "react-router-dom";
import carData from "../../common/components/carData/carData";

const Home = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            <Layout>
                <section className={'section1 w-full h-5/6 bg-black flex flex-col px-8 items-center'}>
                    <p className={'sm:text-5xl text-2xl font-fig font-bold text-white text-center sm:pt-8 xs:pt-8 pt-40 h-fit sm:w-7/12'}>
                        Discover the world on wheels with our car rental service
                    </p>

                    <img src={car} className={' self-center justify-center sm:pb-0 xs:pb-0 pb-36 sm:pt-0 xs:pt-0 pt-16'} alt={'mainCar'} />
                    <DropOff customClassName={" xl:inset-y-24 relative md:inset-y-24 inset-y-28"} />
                </section>

                <section className={'section2 w-full h-5/6 flex flex-col md:px-8 items-center mt-36 bg-white'}>
                    <div className={'w-5/6 items-center justify-between flex-row flex'}>

                        <h2 className={'text-2xl text-left font-semibold font-fig'}>Rent by Brands</h2>
                        <Link to={'/explore'}>
                        <button className={'transition-all duration-150 hover:scale-110 hover:font-semibold font-fig w-24 flex items-center justify-center gap-2'}>View All <FaArrowRight /></button>
                        </Link>
                    </div>
                    <div className={'lg:w-full w-5/6 mt-10 flex flex-wrap justify-center md:gap-6 gap-4'}>
                        <div className={'sm:w-44 sm:h-36 w-32 h-32 bg-customwhite rounded-lg items-center justify-center flex'}>
                            <div className={'flex items-center justify-around h-5/6 flex-col hover:scale-110 cursor-pointer transition-all duration-150'}>
                                <img src={Toyota} alt={'Toyota'} />
                                <p className={'text-xl font-fig w-fit text-black text-center'}>Toyota</p>
                            </div>
                        </div>
                        <div className={'sm:w-44 sm:h-36 w-32 h-32 bg-customwhite rounded-lg items-center justify-center flex'}>
                            <div className={'flex items-center justify-around h-5/6 flex-col hover:scale-110 cursor-pointer transition-all duration-150'}>
                                <img src={Suzuki} alt={'Suzuki'} />
                                <p className={'text-xl font-fig w-fit text-black text-center'}>Suzuki</p>
                            </div>
                        </div>
                        <div className={'sm:w-44 sm:h-36 w-32 h-32 bg-customwhite rounded-lg items-center justify-center flex'}>
                            <div className={'flex items-center justify-around h-5/6 flex-col hover:scale-110 cursor-pointer transition-all duration-150'}>
                                <img src={Honda} alt={'Honda'} />
                                <p className={'text-xl font-fig w-fit text-black text-center'}>Honda</p>
                            </div>
                        </div>
                        <div className={'sm:w-44 sm:h-36 w-32 h-32 bg-customwhite rounded-lg items-center justify-center flex'}>
                            <div className={'flex items-center justify-around h-5/6 flex-col hover:scale-110 cursor-pointer transition-all duration-150'}>
                                <img src={Changan} alt={'Changan'} />
                                <p className={'text-xl font-fig w-fit text-black text-center'}>Changan</p>
                            </div>
                        </div>
                        <div className={'sm:w-44 sm:h-36 w-32 h-32 bg-customwhite rounded-lg items-center justify-center flex'}>
                            <div className={'flex items-center gap-2 justify-around h-5/6 flex-col hover:scale-110 cursor-pointer transition-all duration-150'}>
                                <img src={KIA} className={'pt-4'} alt={'KIA'} />
                                <p className={'text-xl font-fig w-fit text-black text-center mt-1'}>KIA</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Section 3 */}
                <section className="my-14 bg-customwhite py-10 flex flex-col gap-10">
                    <div className="heading flex flex-col items-center gap-6">
                        <p className="text-4xl text-center font-fig font-black">Our Impressive Collection Of Cars</p>
                        <p className="text-center font-fig text-md w-7/12">Ranging from elegant sedans to powerful sports cars, all carefully selected to provide our customers with the ultimate driving experience.</p>
                    </div>
                    <div className="filter flex sm:gap-10 gap-4 justify-center sm:flex-nowrap flex-wrap w-full">
                        <button className="hover:bg-black hover:text-white font-fig rounded-full shadow-md py-2 sm:w-32 w-24 transition-all duration-150 bg-white">Popular Car</button>
                        <button className="hover:bg-black hover:text-white font-fig rounded-full shadow-md py-2 sm:w-32 w-24 transition-all duration-150 bg-white">Luxury Car</button>
                        <button className="hover:bg-black hover:text-white font-fig rounded-full shadow-md py-2 sm:w-32 w-24 transition-all duration-150 bg-white">Budget Car</button>
                        <button className="hover:bg-black hover:text-white font-fig rounded-full shadow-md py-2 sm:w-32 w-24 transition-all duration-150 bg-white">Family Car</button>
                    </div>
                    <div className="card-container flex justify-center sm:px-8 px-2">
                        <div className="flex flex-col md:flex-row md:flex-wrap sm:gap-10 gap-10 justify-center items-center w-full">
                            {carData.slice(0, 6).map(car => (
                                <Card
                                    key={car.id}
                                    id={car.id}
                                    img={car.img}
                                    modelName={car.modelName}
                                    price={car.price}
                                    withDriver={car.withDriver ? "With Driver" : "Self Drive"}
                                    fuelType={car.fuelType}
                                    transmission={car.transmission}
                                    fuelTypeIcon={car.fuelTypeIcon}
                                    personCapacity={car.personCapacity}
                                    imgAlt={`Image of ${car.modelName}`}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="btn-all-cars flex justify-center mt-5">
                        <Link to={'/explore'}>
                        <button className="text-sm font-fig font-bold text-white px-5 py-3 gap-2 bg-black rounded-full flex items-center" onClick={scrollToTop}>See All Cars <FaArrowRight /></button>
                        </Link>
                    </div>
                </section>
                {/* Section 4 */}
                <section className="my-14 bg-white py-10 items-center flex flex-col gap-10">
                    <div className="heading flex flex-col items-center gap-6">
                        <p className="text-4xl font-fig font-black">How It Works ?</p>
                        <p className="text-center font-fig text-md w-8/12">Renting a luxury car has never been easier. Our streamlined process makes it simple for you to book and confirm your vehicle of choice online.</p>
                    </div>
                    <div className="working-section flex items-center md:justify-start justify-center md:w-9/12 w-full sm:flex-nowrap flex-wrap">
                        <div className="card-container flex flex-col gap-6 z-10 md:px-0 px-6">
                            <div className="info-card hover:-translate-y-2 transition-all duration-150 bg-white flex p-2 rounded-lg shadow-lg items-center gap-3 md:w-8/12 w-full">
                                <IoSearchCircleOutline className="text-3xl w-12 bg-customwhite h-14 mx-2 rounded-lg" />
                                <div className="info flex flex-col gap-4">
                                    <p className="text-md font-fig font-bold">Browse and select</p>
                                    <p className="text-sm font-fig">Choose from our wide range of premium cars, select the pickup and return dates and locations that suit you best.</p>
                                </div>
                            </div>
                            <div className="info-card hover:-translate-y-2 transition-all duration-150 bg-white flex p-2 rounded-lg shadow-lg items-center gap-3 md:w-8/12 w-full">
                                <TbCalendarCheck className="text-3xl w-10 bg-customwhite h-14 mx-2 rounded-lg" />
                                <div className="info flex flex-col gap-4">
                                    <p className="text-md font-fig font-bold">Book and confirm</p>
                                    <p className="text-sm font-fig">Book your desired car with just a few clicks and receive an instant confirmation via email or SMS.</p>
                                </div>
                            </div>
                            <div className="info-card hover:-translate-y-2 transition-all duration-150 bg-white flex p-2 rounded-lg shadow-lg items-center gap-3 md:w-8/12 w-full">
                                <TbMoodHappy className="text-3xl w-12 bg-customwhite h-14 mx-2 rounded-lg" />
                                <div className="info flex flex-col gap-4">
                                    <p className="text-md font-fig font-bold">Enjoy your ride</p>
                                    <p className="text-sm font-fig">Pick up your car at the designated location and enjoy your premium driving experience with our top-quality service.</p>
                                </div>
                            </div>
                        </div>

                        <div className="img lg:absolute lg:right-32 rounded-xl lg:h-10/12">
                            <div className="relative right-0 mx-6 rounded-xl h-10/12">
                                <img src={Revo} alt="Revo" className="object-contain bg-transparent" />
                            </div>
                        </div>
                    </div>
                </section>

            </Layout>
        </>
    )
}

export default Home;