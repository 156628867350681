// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCgZ_axLW2qR-LPboi6SUqipbKf7WWTcsA",
    authDomain: "asancars-881c7.firebaseapp.com",
    projectId: "asancars-881c7",
    storageBucket: "asancars-881c7.appspot.com",
    messagingSenderId: "623966801381",
    appId: "1:623966801381:web:864851fa36103dfde0730c",
    measurementId: "G-9JP458S5QK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);