import React from "react";
import AppRoute from "./common/utils/Route/Routes";

function App() {
  return (
    <AppRoute />
  );
}

export default App;
