import React, { useState } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";

const Accordion = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion border-0 py-5 px-2 border-b">
      <div className="accordion-header flex items-center" onClick={toggleAccordion}>
        <div className="flex gap-5">
          <div className={`tall-bullet w-1 rounded-tr-lg rounded-br-lg ${isOpen ? "bg-customRed" : "bg-customgrey"}`}> 
            </div>
          { isOpen ? <div className="accordion-question font-fig text-customRed">{question}</div> : <div className="accordion-question font-fig text-gray-600">{question}</div>}
        </div>
        <div className="accordion-toggle-icon ml-auto p-0.5">
          {isOpen ? <FiMinus className="text-customRed" /> : <FiPlus className="text-xl" />}
        </div>
      </div>
      <div className={`accordion-answer overflow-hidden transition-all duration-175 ${isOpen ? "max-h-[1000px]" : "max-h-0"}`}>
      <p className={`pt-8 text-gray-500`}>{answer}</p>
      </div>
    </div>
  );
};

export default Accordion;
