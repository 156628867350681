// carData.js

import { BsFuelPumpFill } from 'react-icons/bs';
import CorollaCross from '../../assets/CorollaCross.svg';
import Revo from '../../assets/Revo.svg';
import Fortuner from '../../assets/Fortuner.svg';
import Yaris from '../../assets/Yaris.svg';
import Corolla20181 from  "../../assets/Corolla Ahmed New 5.jpeg"
import Corolla20182 from  "../../assets/Corolla Ahmed New.jpeg"
import Corolla20183 from  "../../assets/Corolla Ahmed New 4.jpeg"
import Corolla20184 from  "../../assets/Corolla Ahmed New 3.jpeg"
import Corolla20185 from  "../../assets/Corolla Ahmed New 2.jpeg"
import Corolla20186 from  "../../assets/Corolla Ahmed New 2.jpeg"
import Corollaold1 from  "../../assets/Corolla Ahmed Old 3.jpg"
import Corollaold2 from  "../../assets/Corolla Ahmed Old 2.jpg"
import Corollaold3 from  "../../assets/Corolla Ahmed Old 4.jpg"
import Corollaold4 from  "../../assets/Corolla Ahmed Old.jpg"
import Merc from  "../../assets/Merc Yahya.jpg"
import Merc1 from  "../../assets/Merc Yahya 2.jpg"
import Merc2 from  "../../assets/Merc Yahya 3.jpg"
import Merc3 from  "../../assets/Merc Yahya 4.jpg"
import Merc4 from  "../../assets/Merc Yahya 5.jpg"
import Accord from  "../../assets/Accord Yahya.jpg"
import Accord2 from  "../../assets/Accord Yahya 2.jpg"
import Accord3 from  "../../assets/Accord Yahya 3.jpg"
import Accord4 from  "../../assets/Accord Yahya 4.jpg"
import Accord5 from  "../../assets/Accord Yahya 5.jpg"
import Civic2023 from  "../../assets/Honda Civic Oriel 2023.jpg"
import Civic20231 from  "../../assets/Honda Civic Oriel 2023 2.jpg"
import Civic20232 from  "../../assets/Honda Civic Oriel 2023 3.jpg"
import Civic20233 from  "../../assets/Honda Civic Oriel 2023 4.jpg"
import Civic20234 from  "../../assets/Honda Civic Oriel 2023 5.jpg"
import MamoonSurf from  "../../assets/Mamoon Surf.jpg"
import MamoonSurf2 from  "../../assets/Mamoon Surf 2.jpg"
import MamoonSurf3 from  "../../assets/Mamoon Surf 3.jpg"
import Revo20222 from "../../assets/REVO 2022 2.webp"
import Revo20223 from "../../assets/REVO 2022 3.webp"
import Revo20224 from "../../assets/REVO 2022 4.webp"
import revo2021 from  "../../assets/Toyota revo 2021.webp"
import revo20212 from  "../../assets/Toyota revo 2021 2.webp"
import revo20213 from  "../../assets/Toyota revo 2021 3.webp"
import revo20214 from  "../../assets/Toyota revo 2021 4.webp"
import City from "../../assets/City.jpg"
import City1 from "../../assets/City1.jpg"
import City2 from "../../assets/City2.jpg"
import City3 from "../../assets/City3.jpg"




const carData = [
    {
        id: 1,
        img: Corolla20181,
        modelName: "Toyota Corolla GLI 2018",
        price: "12,000",
        PerHr: "1,200",
        Per4Hr: "4,000",
        fuelType: "Petrol",
        transmission: "Manual",
        fuelTypeIcon: <BsFuelPumpFill />,
        personCapacity: "5",
        withDriver : true,
        images :[Corolla20181,Corolla20182,Corolla20183,Corolla20186,Corolla20184,Corolla20185]
    },
    {   id: 2,
        img: Corollaold3,
        modelName: "Toyota Corolla GLI 2012",
        price: "10,000",
        PerHr: "1,000",
        Per4Hr: "3,700",
        fuelType: "Petrol",
        transmission: "Auto",
        fuelTypeIcon: <BsFuelPumpFill />,
        personCapacity: "5",
        withDriver : true,
        images :[Corollaold1,Corollaold3,Corollaold2,Corollaold3,Corollaold4]
    },
    {
        id: 3,
        img: Merc,
        modelName: "Mercedes-Benz W126",
        price: "17,000",
        PerHr: "1,800",
        Per4Hr: "4,500",
        fuelType: "Petrol",
        transmission: "Auto",
        fuelTypeIcon: <BsFuelPumpFill />,
        personCapacity: "5",
        withDriver : true,
        images :[Merc,Merc1,Merc2, Merc3, Merc4]
    },
    {
        id: 4,
        img: Accord,
        modelName: "Honda Accord 2003",
        price: "12,000",
        PerHr: "1,500",
        Per4Hr: "4,000",
        fuelType: "Petrol",
        transmission: "Auto",
        fuelTypeIcon: <BsFuelPumpFill />,
        personCapacity: "5",
        withDriver : true,
        images :[Accord,Accord2,Accord3,Accord4,Accord5]
    },
    {
        id: 5,
        img: Civic2023,
        modelName: "Honda Civic Oriel 2023",
        price: "18,000",
        PerHr: "2,500",
        Per4Hr: "7,500",
        fuelType: "Petrol",
        transmission: "Auto",
        fuelTypeIcon: <BsFuelPumpFill />,
        personCapacity: "5",
        withDriver : true,
        images :[Civic2023,Civic20231,Civic20232, Civic20233,Civic20234]
    },
    {
        id: 6,
        img: MamoonSurf,
        modelName: "Toyota Surf",
        price: "22,000",
        PerHr: "2,500",
        Per4Hr: "8,000",
        fuelType: "Petrol",
        transmission: "Auto",
        fuelTypeIcon: <BsFuelPumpFill />,
        personCapacity: "7",
        withDriver : true,
        images :[MamoonSurf,MamoonSurf2,MamoonSurf3]
    },
    {
        id: 7,
        img: Revo20222,
        modelName: "Toyota Revo 4x4 2022",
        price: "25,000",
        PerHr: "3,000",
        Per4Hr: "9,000",
        fuelType: "Petrol",
        transmission: "Auto",
        fuelTypeIcon: <BsFuelPumpFill />,
        personCapacity: "5",
        withDriver : true,
        images :[Revo20222,Revo20223,Revo20224]
    },
    {
        id: 8,
        img: revo2021,
        modelName: "Toyota Revo 4x4 2021",
        price: "11,000 (City Only)",
        PerHr: "3,500",
        Per4Hr: "7,500",
        fuelType: "Petrol",
        transmission: "Auto",
        fuelTypeIcon: <BsFuelPumpFill />,
        personCapacity: "5",
        withDriver : true,
        images :[revo2021,revo20212,revo20213,revo20214]
    },
    {
        id: 9,
        img: City,
        modelName: "Honda City 2007",
        price: "9,000",
        PerHr: "900",
        Per4Hr: "3,500",
        fuelType: "Petrol",
        transmission: "Manual",
        fuelTypeIcon: <BsFuelPumpFill />,
        personCapacity: "5",
        withDriver : true,
        images :[City,City1,City2,City3]
    },
    {   id: 10,
        img: Corollaold3,
        modelName: "Toyota Corolla GLI 2012",
        price: "10,500",
        PerHr: "1,200",
        Per4Hr: "3,900",
        fuelType: "Petrol",
        transmission: "Auto",
        fuelTypeIcon: <BsFuelPumpFill />,
        personCapacity: "5",
        withDriver : true,
        images :[Corollaold1,Corollaold3,Corollaold2,Corollaold3,Corollaold4]
    },

    {
        id: 11,
        img: Revo20222,
        modelName: "Toyota Revo 4x4 2022",
        price: "25,000",
        PerHr: "3,000",
        Per4Hr: "9,000",
        fuelType: "Petrol",
        transmission: "Auto",
        fuelTypeIcon: <BsFuelPumpFill />,
        personCapacity: "5",
        withDriver : true,
        images :[Revo20222,Revo20223,Revo20224]
    },
    {
        id: 12,
        img: City,
        modelName: "Honda City 2007",
        price: "9,000",
        PerHr: "900",
        Per4Hr: "3,500",
        fuelType: "Petrol",
        transmission: "Manual",
        fuelTypeIcon: <BsFuelPumpFill />,
        personCapacity: "5",
        withDriver : true,
        images :[City,City1,City2,City3]
    },

    {
        id: 13,
        img: Civic20234,
        modelName: "Honda Civic Oriel 2023",
        price: "18,000",
        PerHr: "2,500",
        Per4Hr: "7,500",
        fuelType: "Petrol",
        transmission: "Auto",
        fuelTypeIcon: <BsFuelPumpFill />,
        personCapacity: "5",
        withDriver : true,
        images :[Civic2023,Civic20231,Civic20232, Civic20233,]
    },
    {
        id: 14,
        img: Corolla20184,
        modelName: "Toyota Corolla GLI 2018",
        price: "12,000",
        PerHr: "1,200",
        Per4Hr: "4,000",
        fuelType: "Petrol",
        transmission: "Manual",
        fuelTypeIcon: <BsFuelPumpFill />,
        personCapacity: "5",
        withDriver : true,
        images :[Corolla20184,Corolla20182,Corolla20183,Corolla20186,Corolla20184,Corolla20185]
    },
];

export default carData;
