import React from "react";
import { useParams } from "react-router-dom";
import CarDetails from "../../common/components/CarDetails/CarDetails";
import Layout from "../../common/components/Layout/Layout";

const RentVehicle = ({ cars }) => {
    const { id } = useParams();
    const car = cars.find(car => car.id === parseInt(id));

    return (
        <Layout>
            <CarDetails car={car} />
        </Layout>
    );
}

export default RentVehicle;
