import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../../../pages/Home/Home";
import Business from "../../../pages/Business/Business";
import Rent from "../../../pages/Rent/Rent";
import Explore from "../../../pages/Explore/Explore";
import AboutUs from "../../../pages/About Us/AboutUs";
import NotFound from "../../../pages/Not Found/NotFound";
import RentVehicle from "../../../pages/Car Details/Details";
import carData from "../../components/carData/carData";

const AppRoute = () => {
    console.log('carData in AppRoute:', carData); // Debug log to check carData

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/business" element={<Business />} />
            <Route path="/rent" element={<Rent />} />
            <Route path="/explore" element={<Explore />} />
            <Route path="/about_us" element={<AboutUs />} />
            <Route path="/car_details/:id" element={<RentVehicle cars={carData} />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default AppRoute;
