import React, { useState } from "react";
import Accordion from "../Accordion/Accordion";

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const faqData = [
        {
            question: "What is AsanCars?",
            answer: "AsanCars is Pakistan’s premier vehicle sharing platform. It is an online platform \n" +
                "that  aims to connects vehicle owners with potential renters in a manner that \n" +
                "prioritizes trust and convenience.",
        },
        {
            question: "What do I need to book a Car?",
            answer: "To book a car on AsanCars you must be above the age of 21, have a valid copy of \n" +
                "your CNIC and Driving License (For self-drive).",
        },
        {
            question: "What do I need to list my Car?",
            answer: "To list your vehicle on AsanCars we require few documents of yourself and of your \n" +
                "vehicle. Our team will verify your documents and will contact you within 48 hours. \n" +
                "*See the Registration Page.",
        },
        {
            question: "Is Fuel included in the trip?",
            answer: "For shorter trips some host, may not require you to pay for the fuel. In trips of \n" +
                "longer duration, you would be required to pay for the fuel you have used. ",
        },
        {
            question: "What are the durations of the trip?",
            answer: "The duration of each trip depends upon the renter. You may choose to rent a \n" +
                "vehicle for an hour, four hours or a day. You may also choose to opt-in with drive \n" +
                "or without driver. ",
        },
        {
            question: "What types of vehicles are available to rent?",
            answer: "Wide range of vehicles are listed by our hosts including sedans, hatchback, jeeps \n" +
                "to choose from. ",
        },
    ];

    return (
        <>
            <div className="faq-container">
                {faqData.map((faq, index) => (
                    <Accordion
                        key={index}
                        question={faq.question}
                        answer={faq.answer}
                        isOpen={index === activeIndex}
                        toggleAccordion={() => toggleAccordion(index)}
                    />
                ))}
            </div>
        </>
    );
};

export default FAQ;
