import React, {useEffect, useState} from "react";
import Layout from "../../common/components/Layout/Layout";
import { RiSteeringFill } from "react-icons/ri";
import client from "../../common/assets/client.svg"
import mob_client from "../../common/assets/mobile-view-client-rent.svg"
import { db } from "../../common/config/Firebase/Firebase.js";
import { collection, doc, setDoc } from "firebase/firestore";
import {useLocation} from "react-router";

const Rent = () => {

    const location = useLocation();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");
    const [car, setCar] = useState("");
    const [date, setDate] = useState("");
    const [timeDuration, setTimeDuration] = useState("");
    const [pickupTime, setPickupTime] = useState("");
    const [purpose, setPurpose] = useState("");
    const [notCheck, setCheck] = useState(false);
    const [terms, setTerms] = useState("Not Accepted");

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const carModelName = params.get('car');
        if (carModelName) {
            setCar(carModelName);
        }
    }, [location.search]);

    let credentials = {
        name: name,
        email: email,
        contact: contact,
        date: date,
        car: car,
        timeDuration: timeDuration,
        pickupTime: pickupTime,
        purpose: purpose,
        termsAndConditions: notCheck,
        timeStamp: new Date().getTime(),
    }

    const asanRef = collection(db, "AsanCars");
    const subCollectionRef = collection(asanRef, 'Rent', 'users');

    const handleChangeName = e => {
        setName(e.target.value)
    }

    const handleChangeEmail = e => {
        setEmail(e.target.value)
    }

    const handleChangeContact = e => {
        setContact(e.target.value)
    }

    const handleChangeCar = e => {
        setCar(e.target.value)
    }

    const handleChangeDate = e => {
        setDate(e.target.value)
    }

    const handleChangeTimeDuration = e => {
        setTimeDuration(e.target.value)
    }

    const handleChangePickupTime = e => {
        setPickupTime(e.target.value)
    }

    const handleChangePurpose = e => {
        setPurpose(e.target.value)
    }

    const handleChangeCheck = e => {
        setCheck(!notCheck)
        notCheck === true ? setTerms("Accepted Terms") : console.log(terms);
    }

    const handleClickSubmit = async (e) => {
        e.preventDefault();
        console.table(name, email, contact, date, car, timeDuration, pickupTime, purpose, notCheck, terms);
        try {
            const docRef = await setDoc(doc(subCollectionRef), credentials);
            alert('Your Request Has Been Forwarded');
            console.log(docRef);
        } catch (error) {
            alert(error);
        }
    };

    return (
        <>
            <Layout>
                <div className="heading shadow-lg flex items-center sm:justify-start justify-center">
                <p className="flex font-fig items-center font-bold gap-3 lg:text-4xl text-3xl lg:ml-52 lg:my-10 my-6 lg:justify-start justify-center">Rent <RiSteeringFill style={{ strokeWidth: 1 }} /> </p>
                </div>

                <section className="sm:py-14 sm:px-10">
                    <div className="container flex lg:flex-row items-center flex-col-reverse justify-center gap-8">
                        <form className="animation-upround shadow-lg gap-6 items-center pt-10 pb-8 flex flex-col w-fit rounded-lg md:px-2 px-6 sm:mx-0 mx-8">
                            <div className="heading flex flex-col gap-3">
                                <p className="font-fig font-black text-2xl">
                                    Reserve Your Vehicle
                                </p>
                            </div>
                            <div className="inputs flex flex-col w-fit lg:w-fit gap-4">
                                <input value={name} onChange={handleChangeName} type="text" className="outline-none font-fig px-4 py-1 border-0 border-black border-b" placeholder="Name" />
                                <input value={email} onChange={handleChangeEmail} type="email" className="outline-none font-fig px-4 py-1 border-0 border-black border-b" placeholder="Email" />
                                <div className="flex md:flex-nowrap flex-wrap md:gap-1 gap-4 md:w-fit w-full">
                                    <input value={contact} onChange={handleChangeContact} type="text" className="outline-none font-fig px-4 py-1 border-0 border-black border-b" placeholder="Contact" />
                                    <input value={date} onChange={handleChangeDate} type="date" className="outline-none font-fig px-4 py-1 border-0 border-black border-b lg:w-full" placeholder="Date" />
                                </div>
                                <input value={car} onChange={handleChangeCar} type="text" className="outline-none font-fig px-4 py-1 border-0 border-black border-b" placeholder="Car" />
                                <input value={timeDuration} onChange={handleChangeTimeDuration} type="text" className="outline-none font-fig px-4 py-1 border-0 border-black border-b" placeholder="Time Duration" />
                                <input value={pickupTime} onChange={handleChangePickupTime} type="time" className="outline-none font-fig px-4 py-1 border-0 border-black border-b" placeholder="Pickup Time" />
                                <input value={purpose} onChange={handleChangePurpose} type="text" className="outline-none font-fig px-4 py-1 border-0 border-black border-b" placeholder="Comments" />
                                <div className="check flex items-center gap-x-2">
                                    <input value={notCheck} onChange={handleChangeCheck} type="checkbox" />
                                    <label className="text-sm font-fig">I accept the terms & conditions of AsanCars.</label>
                                </div>
                                <button onClick={handleClickSubmit} className="font-fig bg-black text-white p-3 rounded-md transition-all duration-200 hover:shadow-xl">Submit</button>
                            </div>
                        </form>
                        <img src={client} className="w-c36 lg:block hidden" alt="client" />
                        <img src={mob_client} className="lg:hidden block w-full" alt="mobile-client" />
                    </div>
                </section>


            </Layout>
        </>
    )
}

export default Rent;