import React, { useState } from "react";
import Layout from "../../common/components/Layout/Layout";
import { FaBriefcase } from "react-icons/fa";
import person from "../../common/assets/person-driving.webp";
import { db } from "../../common/config/Firebase/Firebase.js";
import { collection, doc, setDoc } from "firebase/firestore";
import {Link} from "react-router-dom";

const Business = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");
    const [notCheck, setCheck] = useState(false);
    const [terms, setTerms] = useState("Not Accepted");

    let credentials = {
        name: name,
        email: email,
        contact: contact,
        termsAndConditions: notCheck,
    }

    const asanRef = collection(db, "AsanCars");
    const subCollectionRef = collection(asanRef, 'Business', 'users');

    const handleChangeName = e => {
        setName(e.target.value)
    }

    const handleChangeEmail = e => {
        setEmail(e.target.value)
    }

    const handleChangeContact = e => {
        setContact(e.target.value)
    }

    const handleChangeCheck = e => {
        setCheck(!notCheck)
        notCheck === true ? setTerms("Accepted Terms") : console.log(terms);
    }

    const handleClickSubmit = async e => {
        e.preventDefault();
        console.table(name, email, contact, notCheck, terms)
        try {

            const docRef = await setDoc(doc(subCollectionRef), credentials);
            alert("Data Has Been Saved Successfully");
            console.log(docRef);

        } catch (error) {
            alert(error)
        }

    }

    return (
        <>
            <Layout>
                <p className="flex font-fig items-center font-bold gap-3 lg:text-4xl text-3xl lg:ml-52 lg:my-10 my-6 lg:justify-start justify-center">Business <FaBriefcase /> </p>
                <section className="flex flex-col">
                    <div className="img-container w-full">
                        <img src={person} alt="person" className="object-contain w-full" />
                    </div>
                    <form className="relative lg:-inset-y-80 lg:inset-x-1/2 lg:ml-5 bottom-9 shadow-lg lg:gap-6 gap-6 items-center lg:px-0 px-5 lg:pt-12 lg:pb-8 py-5 bg-white rounded-md flex flex-col sm:w-1/4 w-5/6 lg:self-start self-center">
                        <div className="heading lg:w-8/12 flex flex-col gap-3">
                            <p className="font-fig font-black lg:text-5xl text-3xl ">
                                List <br /> Your Vehicle
                            </p>
                            <p className="font-fig font-light text-md">
                                List your vehicle for rent and start generating revenue.
                            </p>
                        </div>
                        <div className="inputs flex flex-col lg:w-8/12 lg:gap-4 gap-4">
                            <input value={name} onChange={handleChangeName} type="text" className="outline-none font-fig rounded-md bg-customsilver shadow px-4 py-1 placeholder-customgrey" placeholder="Name" />
                            <input value={email} onChange={handleChangeEmail} type="email" className="outline-none font-fig rounded-md bg-customsilver shadow px-4 py-1 placeholder-customgrey" placeholder="Email" />
                            <input value={contact} onChange={handleChangeContact} type="tel" className="outline-none font-fig rounded-md bg-customsilver shadow px-4 py-1 placeholder-customgrey" placeholder="Contact" />
                            <div className="check flex items-center gap-x-2">
                                <input value={notCheck} onChange={handleChangeCheck} type="checkbox" />
                                <label className="text-sm font-fig">I accept the terms & conditions of AsanCars.</label>
                            </div>
                            <Link to={'https://forms.gle/WvAabUe1S7NnXrbRA'}  target="_blank">
                            <button className="font-fig bg-black text-white lg:p-3 p-2  text-center w-full text-lg h-14 rounded-xl cursor-pointer font-semibold">Next Steps</button>
                            </Link>
                        </div>
                    </form>
                    <div className="content flex flex-col gap-5 lg:pl-10 px-6 lg:relative lg:-inset-y-96 lg:py-0 py-10">
                        <p className="font-fig lg:w-6/12">We are Pakistan&rsquo;s premier vehicle sharing platform. We at AsanCars aims to foster trust within communities by not only offering vehicle rental services but also providing a platform to list vehicles. As a tech company, we connect vehicle owners with potential renters in a manner that prioritizes trust and convenience.</p>
                        <p className="sm:w-6/12 font-extrabold font-fig">Join us in our journey of making this Pakistani startup a global phenomenon</p>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Business;