import React, { useState } from "react";
import Layout from "../../common/components/Layout/Layout";
import Card from "../../common/components/Card/Card.jsx";
import couple from "../../common/assets/couple.webp";
import { FaCircleExclamation } from "react-icons/fa6";
import Jeep from "../../common/assets/jeep-photo.svg"
import MsgQuestion from "../../common/assets/message-question.svg";
import FAQ from "../../common/components/FAQ/faq";
import { db } from "../../common/config/Firebase/Firebase.js";
import { doc, collection, setDoc } from "firebase/firestore";

import carData from "../../common/components/carData/carData";

const AboutUs = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    let credentials = {
        name: name,
        email: email,
    }

    const asanRef = collection(db, "AsanCars");
    const subCollectionRef = collection(asanRef, 'AboutUs', 'users');

    const handleChangeName = e => {
        setName(e.target.value)
    }

    const handleChangeEmail = e => {
        setEmail(e.target.value)
    }

    const handleClickSubmit = async e => {
        e.preventDefault();
        console.table(name, email)
        try {

            const docRef = await setDoc(doc(subCollectionRef), credentials);
            alert("Data Has Been Saved Successfully");
            console.log(docRef);

        } catch (error) {
            alert(error)
        }
    }

    return (
        <>
            <Layout>
            <p className="flex font-fig items-center font-bold gap-3 lg:text-4xl text-3xl lg:ml-52 lg:my-10 my-6 lg:justify-start justify-center">About Us <FaCircleExclamation className="text-3xl" /> </p>
                <section className="flex flex-col">
                    <div className="img-containerc w-full">
                        <img src={couple} alt="person" className="object-contain w-full object-center" />
                    </div>
                    <form className=" justify-center relative lg:-inset-y-80 lg:inset-x-2/3 lg:ml-24 bottom-9 shadow-lg lg:gap-10 gap-3 items-center lg:px-0 px-5 lg:pt-9 lg:pb-8 py-5 bg-white rounded-md flex flex-col sm:w-1/4 w-10/12 lg:self-start self-center h-[450px]">
                        <div className="heading lg:w-8/12 flex flex-col gap-3">
                            <p className="font-fig font-black md:text-4xl text-3xl">
                                Get Updates <br /> of AsanCars
                            </p>
                            <p className="font-fig font-light text-lg">
                                Submit to stay updated with AsanCars
                            </p>
                        </div>
                        <div className="inputs flex flex-col lg:w-8/12 gap-4">
                            <input value={name} onChange={handleChangeName} type="text" className="outline-none font-fig rounded-md bg-customsilver shadow px-4 py-1 placeholder-customgrey text-lg" placeholder="Name" />
                            <input value={email} onChange={handleChangeEmail} type="email" className="outline-none font-fig rounded-md bg-customsilver shadow px-4 py-1 placeholder-customgrey text-lg" placeholder="Email" />
                            <button onClick={handleClickSubmit} className="font-fig bg-black text-white p-3 rounded-md text-lg font-semibold">Submit</button>
                        </div>
                    </form>
                    <div className="content flex flex-col gap-5 lg:pl-10 px-6 lg:relative lg:-inset-y-64 lg:py-0 py-10">
                        <div className="content-written flex flex-col gap-5 lg:w-9/12">
                            <p className="font-fig">Utilizing the Pakistan&rsquo;s entrepreneurial wave, we started developing AsanCars in November 2023, aiming to revolutionize vehicle rental services in Pakistan. We are Pakistan&rsquo;s premier vehicle sharing
                                platform.</p>
                            <p> We at AsanCars aims to foster trust within communities by not only offering vehicle rental services but also providing a platform to list vehicles. As a tech company, we connect vehicle owners with potential renters in a manner that prioritizes trust and convenience. Whether you're in need of a sedan, coupe, or hatchback, our platform has a wide range of options to choose from. If you're interested in becoming a part of our community of vehicle hosts, visit our <a className={'text-red-800'} target={'_blank'}  rel="noreferrer" href={'https://forms.gle/WvAabUe1S7NnXrbRA'}>registration page</a> today.</p>
                            <p className="lg:w-6/12 font-extrabold font-fig">Join us in our journey of making this Pakistani startup a global phenomenon</p>
                        </div>
                    </div>

                </section>
                {/* Section 2 */}
                <section className="2xl:px-24 xl:px-20 lg:px-18 md:px-14 sm:px-10 px-8 md:mt-0 mt-10 mb-10 w-full flex md:flex-nowrap flex-wrap justify-center gap-5">
                    <div className="flex flex-col gap-6 w-full">
                        <img src={Jeep} alt="Jeep" className="object-contain md:w-11/12" />
                        <div className="content flex flex-col gap-3">
                            <p className="text-customRed text-lg  font-fig">Disclaimer*</p>
                            <p className="font-fig font-semibold text-sm">ASANCARS DO NOT OWN VEHICLE OR A FLEET OF VEHICLES. WE ARE JUST A PLATFORM DESIGNED TO CONNECT VEHICLE OWNERS WITH LOCALS WILLING TO BOOK THOSE VEHICLES.</p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-6 w-full md:mt-0 mt-16">
                        <div className="heading flex flex-col gap-4">
                            <p className="flex gap-3 items-center font-fig w-full"> <img src={MsgQuestion} alt="" /> FAQ Questions </p>
                            <p className="font-fig font-bold text-3xl">Frequently Asked Questions</p>
                        </div>
                        <div className="accordion-container">
                            <FAQ />
                        </div>
                    </div>
                </section>
                {/* Section 3 */}
                <section className="my-20 flex flex-col gap-10">
                    <div className="heading flex flex-col items-center gap-6">
                        <p className="text-4xl font-fig font-black">Explore Our Cars</p>
                        <p className="text-center font-fig text-md w-8/12">Ranging from elegant sedans to powerful sports cars, all carefully selected to provide our customers with the ultimate driving experience.</p>
                    </div>
                    <div className="card-container flex justify-center sm:px-8 px-2">
                        <div className="flex flex-col md:flex-row md:flex-wrap sm:gap-10 gap-10 justify-center items-center w-full">
                            {carData.slice(0, 3).map(car => (
                                <Card
                                    key={car.id}
                                    id={car.id}
                                    img={car.img}
                                    modelName={car.modelName}
                                    price={car.price}
                                    withDriver={car.withDriver ? "With Driver" : "Self Drive"}
                                    fuelType={car.fuelType}
                                    transmission={car.transmission}
                                    fuelTypeIcon={car.fuelTypeIcon}
                                    personCapacity={car.personCapacity}
                                    imgAlt={`Image of ${car.modelName}`}
                                />
                            ))}
                        </div>

                    </div>
                </section>
            </Layout>
        </>
    )
}

export default AboutUs;