import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from 'react-router-dom';
import { GiGearStickPattern } from "react-icons/gi";
import { RiSteeringLine } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { BsFuelPump } from "react-icons/bs";
const CarDetails = ({ car }) => {

    const navigate = useNavigate();

    const handleBookNow = () => {
        navigate(`/rent?car=${car.modelName}`);
    };

    if (!car) return <p>Loading...</p>;

    return (
        <section className='w-full flex flex-col items-center justify-center'>
            <div className="heading md:my-10 my-5 px-20 md:px-32 md:w-fit w-full flex items-center justify-center flex-col pt-10 sm:pt-20">
                <p className="font-fig md:text-5xl text-3xl font-bold md:w-fit w-full text-center">{car.modelName}</p>
            </div>
            <div className="container px-10 py-4 md:mb-24 mb-10 pt-5">
                <div className="carousel flex justify-center items-center flex-col gap-10">
                    <Carousel
                        axis="horizontal"
                        infiniteLoop={true}
                        dynamicHeight={true}
                        statusFormatter={() => ""}
                        showArrows={true}
                        autoPlay={true}
                    >
                        {car.images?.map((image, index) => (
                            <div key={index}>
                                <img src={image} className="object-contain" alt={`Car ${index + 1}`} />
                            </div>
                        ))}
                    </Carousel>
                    <div className="vehicle-specs flex sm:justify-around justify-center items-center gap-x-8 md:gap-2 md:w-8/12 w-full md:flex-nowrap flex-wrap gap-y-8">
                        <div className="flex flex-col gap-1.5 justify-center items-center">
                            <p className="font-fig sm:text-2xl text-xl font-semibold">Per/hr</p>
                            <p className="font-fig text-xl">{car.PerHr}</p>
                        </div>
                        <div className="flex flex-col gap-1.5 justify-center items-center">
                            <p className="font-fig sm:text-2xl text-xl font-semibold">Per/4hr</p>
                            <p className="font-fig text-xl">{car.Per4Hr}</p>
                        </div>
                        <div className="flex flex-col gap-1.5 justify-center items-center">
                            <p className="font-fig sm:text-2xl text-xl font-semibold">Per/day</p>
                            <p className="font-fig text-xl">{car.price}</p>
                        </div>
                        <div className="flex flex-col gap-3 justify-center items-center">
                            <p className="font-fig sm:text-2xl text-xl font-semibold">{car.transmission}</p>
                            <GiGearStickPattern className='text-xl' />
                        </div>
                        <div className="flex flex-col gap-3 justify-center items-center">
                            <p className="font-fig sm:text-2xl text-xl font-semibold">{car.withDriver ? "With Driver" :"Self Drive" }</p>
                            <RiSteeringLine strokeWidth={1} className='text-xl' />
                        </div>
                        <div className="flex flex-col gap-3 justify-center items-center">
                            <p className="font-fig sm:text-2xl text-xl font-semibold">{car.personCapacity}</p>
                            <FiUsers className='text-xl' />
                        </div>
                        <div className="flex flex-col gap-3 justify-center items-center">
                            <p className="font-fig sm:text-2xl text-xl font-semibold">{car.fuelType}</p>
                            {car.fuelTypeIcon || <BsFuelPump className='text-xl' />}
                        </div>
                    </div>
                    <button onClick={handleBookNow} className="px-3 py-2 transition-all duration-175 border-black border-2 hover:text-white hover:bg-black text-black font-fig font-semibold md:w-4/12 w-10/12 rounded-full">Book Now</button>
                </div>
            </div>
        </section>
    );
};

export default CarDetails;
