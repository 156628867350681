import React from "react";
import Layout from "../../common/components/Layout/Layout"

const NotFound = () => {
    return(
        <>
        <Layout>

        </Layout>
        </>
    )
}

export default NotFound;