import React from "react";
import Layout from "../../common/components/Layout/Layout";
import DropOff from "../../common/components/DropOff/Dropoff";
import Card from "../../common/components/Card/Card.jsx";

import carData from "../../common/components/carData/carData";

const Explore = () => {

    return (
        <>
            <Layout>
                {/* Section 1 */}
                <section>
                    <div className="bg-black pt-24">
                        <p className="font-fig font-bold text-white text-4xl text-center pt-6 drop-shadow-xl py-12">Explore</p>
                        <DropOff customClassName={"relative lg:inset-x-16 sm:inset-x-8 inset-y-5 inset-x-5"} />
                    </div>
                    </section>
                    {/* Section 2 */}
                    <section className="md:my-14 bg-grey-50 lg:py-20 sm:py-36 py-64 flex flex-col gap-10">
                    <div className="heading flex flex-col items-center gap-6 md:px-0 px-4 sm:pt-10">
                        <p className="text-4xl text-center font-fig font-black">Our Impressive Collection Of Cars</p>
                    </div>
                    <div className="card-container flex justify-center sm:px-8 px-2">
                        <div className="card-container flex justify-center sm:px-8 px-2">
                            <div
                                className="flex flex-col md:flex-row md:flex-wrap sm:gap-10 gap-10 justify-center items-center w-full">
                                {carData.map(car => (
                                    <Card
                                        key={car.id}
                                        id={car.id}
                                        img={car.img}
                                        modelName={car.modelName}
                                        price={car.price}
                                        withDriver={car.withDriver ? "With Driver" : "Self Drive"}
                                        fuelType={car.fuelType}
                                        transmission={car.transmission}
                                        fuelTypeIcon={car.fuelTypeIcon}
                                        personCapacity={car.personCapacity}
                                        imgAlt={`Image of ${car.modelName}`}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    </section>
            </Layout>
        </>
    )
}

export default Explore;