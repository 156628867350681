import React, { useState } from "react";
import companyLogo from "../../assets/company-logo.svg"
import { FaChevronDown } from "react-icons/fa";
import { IoGlobeOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const Navbar = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <nav className="bg-black flex items-center pb-5 pt-3 justify-around">
                <div className="hamburger md:hidden px-5 " onClick={toggleMenu}>
                    <svg width="34" height="25" viewBox="0 0 34 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="0.738026" width="33.2103" height="4.15129" rx="2.07564" fill="#FFFFFF"/>
                        <rect x="1" y="10.4244" width="33.2103" height="4.15129" rx="2.07564" fill="#FFFFFF"/>
                        <rect x="1" y="20.1107" width="33.2103" height="4.15129" rx="2.07564" fill="#FFFFFF"/>
                    </svg>
                </div>
                <section className="md:flex hidden sm:flex-row items-center">
                    <Link to="/">
                        <img src={companyLogo} alt="Company Logo" className="mr-5 md:w-24 w-32"/>
                    </Link>
                    <ul className="flex gap-6 h-8 items-end">
                        <Link to="/rent">
                            <li className="text-white text-md cursor-pointer font-fig">Rent</li>
                        </Link>
                        <Link to="/business">
                            <li className="text-white text-md cursor-pointer font-fig">Business</li>
                        </Link>
                        <Link to="/about_us">
                            <li className="text-white text-md cursor-pointer font-fig flex items-center gap-3">About <FaChevronDown className="mt-0.5" /> </li>
                        </Link>
                    </ul>
                </section>
                <div className="mob-nav md:hidden flex flex-row justify-between items-center w-5/6 px-8 ">
                    <Link to="/">
                        <img src={companyLogo} alt="Company Logo" className="mr-5 w-32"/>
                    </Link>
                    <li className="text-white text-md cursor-pointer font-fig flex items-center gap-1 text-lg">
                        <IoGlobeOutline/> EN
                    </li>
                </div>
                <section className="flex items-end gap-3">
                    <ul className="md:flex hidden gap-5 items-center mt-2 h-10">
                    <li className="text-white text-md cursor-pointer font-fig flex items-center gap-1"><IoGlobeOutline /> EN</li>
                       <Link to={'mailto:smharoonrashid@gmail.com'}>
                        <li className="text-white text-lg cursor-pointer font-fig">Help</li>
                       </Link>
                    </ul>
                    <Link to={'https://forms.gle/WvAabUe1S7NnXrbRA'} target={"_blank"}>
                    <button className="bg-white text-black cursor-pointer font-fig px-4 sm:py-2 py-1.5 font-bold text-md rounded-full mb-0.5 md:flex hidden">Become a Host</button>
                    </Link>
                </section>
            </nav>

            {/* Mobile Hamburger Menu */}

            {isMenuOpen && (
                <div className="md:hidden bg-gray-900 py-2 px-4">
                    <ul className="hamburger flex flex-col gap-4">
                        <Link to="/rent">
                            <li className="text-white text-md cursor-pointer font-fig text-lg">Rent</li>
                        </Link>
                        <Link to="/business">
                            <li className="text-white text-md cursor-pointer font-fig text-lg">Business</li>
                        </Link>
                        <Link to="/about_us">
                            <li className="text-white text-md cursor-pointer font-fig flex items-center gap-3 text-lg">About <FaChevronDown className="mt-0.5" /> </li>
                        </Link>
                        <Link to={'mailto:smharoonrashid@gmail.com'}>
                        <li className="text-white text-lg cursor-pointer font-fig">Help</li>
                        </Link>
                        <Link to={'https://forms.gle/WvAabUe1S7NnXrbRA'} target={"_blank"}>
                            <button className="bg-white text-black cursor-pointer font-fig px-4 sm:py-2 py-1.5 font-bold text-md rounded-full mb-0.5">Become a Host</button>
                        </Link>
                    </ul>
                </div>
            )}
        </>
    )
}

export default Navbar;